<template>
  <SMListTemplate prefix="Tanımlar" title="Satış Dönemleri">
    <template #actions>
      <div class="d-flex align-items-center">
        <v-icon
          color="error cursor-pointer"
          dark
          :disabled="!isSalePeriodSelected"
          class="mr-2"
          @click="showDeleteRecordModal"
        >
          mdi-delete
        </v-icon>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="cursor-pointer"
              dark
              @click="() => fetchSalePeriods()"
              v-bind="attrs"
              v-on="on"
            >
              mdi-reload
            </v-icon>
          </template>
          <span>Yenile</span>
        </v-tooltip>
        <v-text-field
          v-model="search"
          label="Ara"
          dark
          class="mx-4 mt-0 pt-0"
          hide-details="auto"
          single-line
          append-icon="search"
        ></v-text-field>
        <router-link
          :to="{ name: 'def-sale-periods-edit', query: { editable: true } }"
        >
          <v-btn elevation="0" color="success">
            <v-icon small>mdi-plus</v-icon>
            Yeni Satış Dönemi
          </v-btn>
        </router-link>
      </div>
    </template>
    <template #table>
      <v-data-table
        v-model="selectedRecord"
        :headers="headers"
        :items="mappedSalePeriods"
        :search="search"
        :loading="loading"
        :items-per-page="100"
        item-key="id"
        single-select
        show-select
      >
        <template #item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <router-link
                :to="{
                  name: 'def-sale-periods-edit',
                  params: { id: item.id },
                  query: { editable: true },
                }"
              >
                <v-icon color="warning" small v-bind="attrs" v-on="on">
                  mdi-pencil
                </v-icon>
              </router-link>
            </template>
            <span>Düzenle</span>
          </v-tooltip>
        </template>
        <template #item.name="{ item }">
          <router-link
            :to="{
              name: 'def-sale-periods-edit',
              params: { id: item.id },
              query: { editable: false },
            }"
          >
            {{ item.name }}
          </router-link>
        </template>
      </v-data-table>
    </template>
  </SMListTemplate>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import {
  FETCH_SALE_PERIODS,
  DELETE_SALE_PERIOD,
} from "@/core/services/store/definitions/sale-periods.module";

import { formatPrice } from "@/core/helper.js";

import SMListTemplate from "@/view/content/components/SMListTemplate.vue";

export default {
  name: "SalePeriodList",
  components: { SMListTemplate },
  data() {
    return {
      headers: [
        {
          value: "name",
          text: "Satış Dönemi",
          align: "left",
        },
        {
          value: "goldenmade_goal",
          text: "G-Hedef",
          align: "left",
          default: "Belirtilmedi",
        },
        {
          value: "platiniummade_goal",
          text: "P-Hedef",
          align: "left",
          default: "Belirtilmedi",
        },
        {
          value: "siliconmade_goal",
          text: "SM-Hedef",
          align: "left",
          default: "Belirtilmedi",
        },
        {
          value: "siliconhead_goal",
          text: "SH-Hedef",
          align: "left",
          default: "Belirtilmedi",
        },
        {
          value: "sale_goal",
          text: "Satış Hedefi",
          align: "left",
          default: "Belirtilmedi",
        },
        { value: "starts_on", text: "Başlangıç Tarihi", align: "left" },
        { value: "ends_on", text: "Bitiş Tarihi", align: "left" },
        {
          value: "actions",
          text: "",
          sortable: false,
          align: "left",
        },
        { text: "", value: "data-table-select", align: "right" },
      ],
      loading: true,
      selectedRecord: [],
      search: "",
    };
  },
  async mounted() {
    try {
      await this.fetchSalePeriods();
    } catch (error) {
      let message =
        error[0]?.detail ?? "Bir hata oluştu lütfen tekrar deneyiniz";
      this.$toast.error(message, {
        position: "bottom-right",
        duration: 2000,
      });
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions([FETCH_SALE_PERIODS, DELETE_SALE_PERIOD]),
    formatPrice,
    showDeleteRecordModal() {
      const params = {
        title: "Seçtiğiniz satış dönemi silinecek",
        text: "Seçili satış dönemini silmek istediğinize emin misiniz?",
        onConfirm: () => {
          return this.acceptRecordDelete();
        },
      };
      this.$approvalModal.show(params);
    },
    async acceptRecordDelete() {
      try {
        await this.deleteSalePeriod(this.selectedRecord[0]);
        this.$toast.success("Satış Dönemi silindi", {
          position: "bottom-right",
          duration: 2000,
        });
        this.selectedRecord = [];
      } catch (error) {
        this.$toast.error(error[0].detail, {
          position: "bottom-right",
          duration: 2000,
        });
      }
    },
  },
  computed: {
    ...mapGetters(["salePeriods"]),
    isSalePeriodSelected() {
      return this.selectedRecord.length === 1;
    },
    mappedSalePeriods() {
      return this.salePeriods.map((salePeriod) => {
        return {
          ...salePeriod,
          goldenmade_goal:
            this.formatPrice(salePeriod.goldenmade_goal) ?? "Belirtilmedi",
          platiniummade_goal:
            this.formatPrice(salePeriod.platiniummade_goal) ?? "Belirtilmedi",
          siliconmade_goal:
            this.formatPrice(salePeriod.siliconmade_goal) ?? "Belirtilmedi",
          siliconhead_goal:
            this.formatPrice(salePeriod.siliconhead_goal) ?? "Belirtilmedi",
          sale_goal: this.formatPrice(salePeriod.sale_goal) ?? "Belirtilmedi",
          ends_on: this.$moment(salePeriod.ends_on).format("DD/MM/YYYY"),
          starts_on: this.$moment(salePeriod.starts_on).format("DD/MM/YYYY"),
        };
      });
    },
  },
};
</script>
