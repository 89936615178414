<template>
  <v-card>
    <v-toolbar color="primary" class="rounded-0 text-white" height="80px">
      <v-toolbar-title class="ml-3">
        <h2>
          <b v-if="prefix">{{ prefix }} - </b>{{ title }}
        </h2>
      </v-toolbar-title>
      <v-spacer />
      <slot name="actions"></slot>
      <KTTopMenu></KTTopMenu>
    </v-toolbar>
    <v-card-text>
      <slot name="table" />
    </v-card-text>
  </v-card>
</template>

<script>
import KTTopMenu from "@/view/layout/header/TopMenu.vue";
export default {
  name: "SMListTemplate",
  components: { KTTopMenu },
  props: {
    title: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
      default: null,
    },
  },
};
</script>
