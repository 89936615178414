var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SMListTemplate',{attrs:{"prefix":"Tanımlar","title":"Satış Dönemleri"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error cursor-pointer","dark":"","disabled":!_vm.isSalePeriodSelected},on:{"click":_vm.showDeleteRecordModal}},[_vm._v(" mdi-delete ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"cursor-pointer","dark":""},on:{"click":() => _vm.fetchSalePeriods()}},'v-icon',attrs,false),on),[_vm._v(" mdi-reload ")])]}}])},[_c('span',[_vm._v("Yenile")])]),_c('v-text-field',{staticClass:"mx-4 mt-0 pt-0",attrs:{"label":"Ara","dark":"","hide-details":"auto","single-line":"","append-icon":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('router-link',{attrs:{"to":{ name: 'def-sale-periods-edit', query: { editable: true } }}},[_c('v-btn',{attrs:{"elevation":"0","color":"success"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" Yeni Satış Dönemi ")],1)],1)],1)]},proxy:true},{key:"table",fn:function(){return [_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.mappedSalePeriods,"search":_vm.search,"loading":_vm.loading,"items-per-page":100,"item-key":"id","single-select":"","show-select":""},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('router-link',{attrs:{"to":{
                name: 'def-sale-periods-edit',
                params: { id: item.id },
                query: { editable: true },
              }}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Düzenle")])])]}},{key:"item.name",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
            name: 'def-sale-periods-edit',
            params: { id: item.id },
            query: { editable: false },
          }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.selectedRecord),callback:function ($$v) {_vm.selectedRecord=$$v},expression:"selectedRecord"}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }